(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
var QueryHandler = require('./QueryHandler');
var each = require('./Util').each;

/**
 * Represents a single media query, manages it's state and registered handlers for this query
 *
 * @constructor
 * @param {string} query the media query string
 * @param {boolean} [isUnconditional=false] whether the media query should run regardless of whether the conditions are met. Primarily for helping older browsers deal with mobile-first design
 */
function MediaQuery(query, isUnconditional) {
    this.query = query;
    this.isUnconditional = isUnconditional;
    this.handlers = [];
    this.mql = window.matchMedia(query);

    var self = this;
    this.listener = function(mql) {
        // Chrome passes an MediaQueryListEvent object, while other browsers pass MediaQueryList directly
        self.mql = mql.currentTarget || mql;
        self.assess();
    };
    this.mql.addListener(this.listener);
}

MediaQuery.prototype = {

    constuctor : MediaQuery,

    /**
     * add a handler for this query, triggering if already active
     *
     * @param {object} handler
     * @param {function} handler.match callback for when query is activated
     * @param {function} [handler.unmatch] callback for when query is deactivated
     * @param {function} [handler.setup] callback for immediate execution when a query handler is registered
     * @param {boolean} [handler.deferSetup=false] should the setup callback be deferred until the first time the handler is matched?
     */
    addHandler : function(handler) {
        var qh = new QueryHandler(handler);
        this.handlers.push(qh);

        this.matches() && qh.on();
    },

    /**
     * removes the given handler from the collection, and calls it's destroy methods
     *
     * @param {object || function} handler the handler to remove
     */
    removeHandler : function(handler) {
        var handlers = this.handlers;
        each(handlers, function(h, i) {
            if(h.equals(handler)) {
                h.destroy();
                return !handlers.splice(i,1); //remove from array and exit each early
            }
        });
    },

    /**
     * Determine whether the media query should be considered a match
     *
     * @return {Boolean} true if media query can be considered a match, false otherwise
     */
    matches : function() {
        return this.mql.matches || this.isUnconditional;
    },

    /**
     * Clears all handlers and unbinds events
     */
    clear : function() {
        each(this.handlers, function(handler) {
            handler.destroy();
        });
        this.mql.removeListener(this.listener);
        this.handlers.length = 0; //clear array
    },

    /*
        * Assesses the query, turning on all handlers if it matches, turning them off if it doesn't match
        */
    assess : function() {
        var action = this.matches() ? 'on' : 'off';

        each(this.handlers, function(handler) {
            handler[action]();
        });
    }
};

module.exports = MediaQuery;

},{"./QueryHandler":3,"./Util":4}],2:[function(require,module,exports){
var MediaQuery = require('./MediaQuery');
var Util = require('./Util');
var each = Util.each;
var isFunction = Util.isFunction;
var isArray = Util.isArray;

/**
 * Allows for registration of query handlers.
 * Manages the query handler's state and is responsible for wiring up browser events
 *
 * @constructor
 */
function MediaQueryDispatch () {
    if(!window.matchMedia) {
        throw new Error('matchMedia not present, legacy browsers require a polyfill');
    }

    this.queries = {};
    this.browserIsIncapable = !window.matchMedia('only all').matches;
}

MediaQueryDispatch.prototype = {

    constructor : MediaQueryDispatch,

    /**
     * Registers a handler for the given media query
     *
     * @param {string} q the media query
     * @param {object || Array || Function} options either a single query handler object, a function, or an array of query handlers
     * @param {function} options.match fired when query matched
     * @param {function} [options.unmatch] fired when a query is no longer matched
     * @param {function} [options.setup] fired when handler first triggered
     * @param {boolean} [options.deferSetup=false] whether setup should be run immediately or deferred until query is first matched
     * @param {boolean} [shouldDegrade=false] whether this particular media query should always run on incapable browsers
     */
    register : function(q, options, shouldDegrade) {
        var queries         = this.queries,
            isUnconditional = shouldDegrade && this.browserIsIncapable;

        if(!queries[q]) {
            queries[q] = new MediaQuery(q, isUnconditional);
        }

        //normalise to object in an array
        if(isFunction(options)) {
            options = { match : options };
        }
        if(!isArray(options)) {
            options = [options];
        }
        each(options, function(handler) {
            if (isFunction(handler)) {
                handler = { match : handler };
            }
            queries[q].addHandler(handler);
        });

        return this;
    },

    /**
     * unregisters a query and all it's handlers, or a specific handler for a query
     *
     * @param {string} q the media query to target
     * @param {object || function} [handler] specific handler to unregister
     */
    unregister : function(q, handler) {
        var query = this.queries[q];

        if(query) {
            if(handler) {
                query.removeHandler(handler);
            }
            else {
                query.clear();
                delete this.queries[q];
            }
        }

        return this;
    }
};

module.exports = MediaQueryDispatch;

},{"./MediaQuery":1,"./Util":4}],3:[function(require,module,exports){
/**
 * Delegate to handle a media query being matched and unmatched.
 *
 * @param {object} options
 * @param {function} options.match callback for when the media query is matched
 * @param {function} [options.unmatch] callback for when the media query is unmatched
 * @param {function} [options.setup] one-time callback triggered the first time a query is matched
 * @param {boolean} [options.deferSetup=false] should the setup callback be run immediately, rather than first time query is matched?
 * @constructor
 */
function QueryHandler(options) {
    this.options = options;
    !options.deferSetup && this.setup();
}

QueryHandler.prototype = {

    constructor : QueryHandler,

    /**
     * coordinates setup of the handler
     *
     * @function
     */
    setup : function() {
        if(this.options.setup) {
            this.options.setup();
        }
        this.initialised = true;
    },

    /**
     * coordinates setup and triggering of the handler
     *
     * @function
     */
    on : function() {
        !this.initialised && this.setup();
        this.options.match && this.options.match();
    },

    /**
     * coordinates the unmatch event for the handler
     *
     * @function
     */
    off : function() {
        this.options.unmatch && this.options.unmatch();
    },

    /**
     * called when a handler is to be destroyed.
     * delegates to the destroy or unmatch callbacks, depending on availability.
     *
     * @function
     */
    destroy : function() {
        this.options.destroy ? this.options.destroy() : this.off();
    },

    /**
     * determines equality by reference.
     * if object is supplied compare options, if function, compare match callback
     *
     * @function
     * @param {object || function} [target] the target for comparison
     */
    equals : function(target) {
        return this.options === target || this.options.match === target;
    }

};

module.exports = QueryHandler;

},{}],4:[function(require,module,exports){
/**
 * Helper function for iterating over a collection
 *
 * @param collection
 * @param fn
 */
function each(collection, fn) {
    var i      = 0,
        length = collection.length,
        cont;

    for(i; i < length; i++) {
        cont = fn(collection[i], i);
        if(cont === false) {
            break; //allow early exit
        }
    }
}

/**
 * Helper function for determining whether target object is an array
 *
 * @param target the object under test
 * @return {Boolean} true if array, false otherwise
 */
function isArray(target) {
    return Object.prototype.toString.apply(target) === '[object Array]';
}

/**
 * Helper function for determining whether target object is a function
 *
 * @param target the object under test
 * @return {Boolean} true if function, false otherwise
 */
function isFunction(target) {
    return typeof target === 'function';
}

module.exports = {
    isFunction : isFunction,
    isArray : isArray,
    each : each
};

},{}],5:[function(require,module,exports){
var MediaQueryDispatch = require('./MediaQueryDispatch');
module.exports = new MediaQueryDispatch();

},{"./MediaQueryDispatch":2}],6:[function(require,module,exports){
'use strict';

var $body = $('body');

var disclaimerCookie = $.cookie("Alston-Disclaimer");

if (disclaimerCookie === undefined || disclaimerCookie === "") {
    $(".disclaimer").show();
}

$body.on('click', '.disclaimer-close-button', function () {
    $.cookie("Alston-Disclaimer", 1, { expires: 10, path: '/', secure: true });
    $(".disclaimer").hide();
});
},{}],7:[function(require,module,exports){
var	$body = $('body');

$body.on('click', '.js-drop-down', function(){
	var $this,
		showClass;

	showClass = 'show-list';
	$this = $(this);

	// Add a 1 time event listener to close dropdown when clicked outside of dropdown
	if (!$this.hasClass(showClass)) {
		$body.one('click', function(){
			if ($(this).closest('.js-drop-down')[0] !== $this[0]){
				$this.removeClass(showClass);
			}
		});
	}

	$this.toggleClass(showClass);
});


$body.on('click', '.drop-down-list .drop-down-item', function(){
	var $this,
		$value,
		selectedClass;

	$this = $(this);
	$value = $this.closest('.js-drop-down').find('.current-selection-value');

	selectedClass = 'is-selected';
	$this.siblings().removeClass(selectedClass);
	$this.addClass(selectedClass);

	$value.text($this.text());
});

// ALEX WAS HERE

$body.on('click', '.js-filter-drop-down', function () {
    var $this,
		showClass;

    showClass = 'show-list';
    $this = $(this);

    // Add a 1 time event listener to close dropdown when clicked outside of dropdown
    if (!$this.hasClass(showClass)) {
        $body.one('click', function () {
            if ($(this).closest('.js-filter-drop-down')[0] !== $this[0]) {
                $this.removeClass(showClass);
            }
        });
    }

    $this.toggleClass(showClass);
});


$body.on('click', '.filter-drop-down-list .filter-drop-down-item', function () {
    var $this,
		$value,
		selectedClass;

    $this = $(this);
    $value = $this.closest('.js-filter-drop-down').find('.current-selection-value');

    selectedClass = 'is-selected';
    $this.siblings().removeClass(selectedClass);
    $this.addClass(selectedClass);

    //$value.text($this.text());
});

// JENNY WAS HERE

// navigation dropdowns with native selects
$('.drop-down--navigation').change(function(){
	var val = this.value;
	console.log("changed");
	if (!window.location.pathname.includes(val)) {
		window.location.pathname = val;
	}
});
},{}],8:[function(require,module,exports){
'use strict';

var state,
	stateChangers,
	helpers,
	CONSTANTS,
	$module,
	$btnMenuTriggers,
	$btnMenuClose;

$module = $('.js-global-menu-bar');

if (!$module.length){
	return;
}

$btnMenuTriggers = $module.find('.js-menu-triggers');
$btnMenuClose = $module.find('.js-menu-bar-close');

CONSTANTS = {
	FLYOUT_CLOSED: -1
};

state = {
	flyoutClassPrefix: 'show-flyout-panel-',
	flyoutState: CONSTANTS.FLYOUT_CLOSED
};

stateChangers = {
	updateFlyout: function(){
		var removeOldClass,
			addNewClass;

		removeOldClass = function(){
			var classList = $module.attr('class').split(/\s+/);

			classList.forEach(function(item){
				if (item.indexOf(state.flyoutClassPrefix) !== -1){
					$module.removeClass(item);
				}
			});
		};

		addNewClass = function(){
			var panel = $(".flyout-panel:nth-of-type(" + state.flyoutState + ")");
			if(panel.length > 0) {
				$("input.fly-out-focus", panel).trigger('touchstart').focus();
			} 
			$module.addClass(state.flyoutClassPrefix + state.flyoutState);
		};


		removeOldClass();

		if (state.flyoutState !== CONSTANTS.FLYOUT_CLOSED){
			addNewClass();
		}
	},

	setFlyoutPanelState: function(newState){
		if (typeof newState === 'number' &&
			newState >= 0 ){
			// newState >= 0 &&
			// newState < $btnMenuTriggers.length){

			state.flyoutState = newState;
		} else {
			state.flyoutState = CONSTANTS.FLYOUT_CLOSED;
		}

		this.updateFlyout();
	}
};

helpers = {
	isFlyoutClosed: function(){
		return state.flyoutState === CONSTANTS.FLYOUT_CLOSED;
	}
};

$btnMenuTriggers.click(function(){
	var index = ($(this).data('menu-trigger')) ? $(this).data('menu-trigger') : $btnMenuTriggers.index(this);
	if (state.flyoutState === index){
		stateChangers.setFlyoutPanelState();
	} else {
		stateChangers.setFlyoutPanelState(index);
	}

});

$btnMenuClose.click(function(){
	stateChangers.setFlyoutPanelState();
});

$('body').click(function(event){

	if (helpers.isFlyoutClosed()){
		return;
	}

	// If clicked outside of the menu bar and if the flyout is open, then close the flyout
	if ($(event.target).closest('.js-global-menu-bar').length === 0){
		stateChangers.setFlyoutPanelState();
	}
});

exports.isFlyoutClosed = helpers.isFlyoutClosed;

},{}],9:[function(require,module,exports){
$('.navigation-tile-link a').click(function () {
    e.stopPropagation();
});
$('.navigation-tile-link').click(function () {
    var $this = $(this);
    var url = $this.data("navigationlink");
    var target = $this.data("navigationlinktarget");
    if (url !== "" && url !== undefined && (target !== "" && target !== undefined))
        window.open(url, target);
    else
        window.location = url;
});
},{}],10:[function(require,module,exports){
'use strict';

var $module,
    $carousel,
    $carouselNav,
    mediaQuery;

$module = $('.hero');
if (!$module.length ){
  return;
}

mediaQuery = require('enquire.js');
$carousel = $module.find('.hero__items');
$carouselNav = $module.find('.hero__nav');

function tabClick (tab) {
  $('.tabs__category').removeClass('tabs__category--active')
  $('.tabs__content').removeClass('tabs__content--active')
  $('#tabs__category--' + tab).addClass('tabs__category--active')
  $('#tabs__content--' + tab).addClass('tabs__content--active')
}

function initSlick() {
  $carousel.slick({
    asNavFor: '.hero__nav',
    nextArrow: '<button type="button" class="slick-next"></button>',
    prevArrow: '<button type="button" class="slick-prev"></button>',
    appendArrows: $('.hero__arrows'),
    infinite: true
  });

  $carouselNav.slick({
    asNavFor: '.hero__items',
    slidesToShow: 4,
    focusOnSelect: true,
    arrows: false,
    draggable: false
  });
}

function removeSlick() {
  $carousel.slick('unslick');
  $carouselNav.slick('unslick');
}


$('.tabs__category').on('click', function () {
  tabClick($(this).data('tab'));
});

tabClick('first');

//setup media qquery
mediaQuery.register('(max-width: 766px)',{
  match: function(){
    initSlick();
  },
  unmatch: function(){
    removeSlick();
  }
});

$(window).scroll(function() {
  var $nav = $('.navigation--homepage');
  var scrollDistance = $(window).scrollTop();
  var navHeight = parseInt($nav.css('height'));
  var isDesktop = ($(window).width() > 1200) ? true : false;
  // if (scrollDistance >= navHeight && $nav.hasClass('navigation--fixed')) {
  if (scrollDistance >= navHeight && isDesktop) {
    $nav.css({transform: 'translateY(-100%)'});
    if (scrollDistance >= navHeight + 50) {
      $nav.css({transform: 'translateY(0)'});
      $nav.addClass('navigation--fixed');
    }
  } else {
    $nav.css({transform: 'translateY(0)'});
    $nav.removeClass('navigation--fixed');
  }
});

},{"enquire.js":5}],11:[function(require,module,exports){
'use strict';

var cachedWindowWidth,
	$container,
	heroVideoResize,
	$selectors = require('./selectors').$selectors,
	mediaQuery = require('enquire.js');

heroVideoResize = (function () {

    var $video,
		aspectRatio,

		computeWindowScreen,
		handleVideoResize,
		initializeVideoResize;

    computeWindowScreen = function () {
        var winWidth,
			winHeight,
			largerDimension,

		winWidth = window.innerWidth;
        winHeight = $container.height();

        largerDimension = (winWidth / winHeight < aspectRatio) ? { 'height': winHeight } : { 'width': winWidth };

        return largerDimension;

    };


    handleVideoResize = function () {
    
        var resizeBy = computeWindowScreen();

        if (resizeBy.hasOwnProperty('width')) {

            $video.css({
                width: resizeBy['width'] / 16 + 'em',
                height: ''
            })
        } else {
            $video.css({
                width: '',
                height: resizeBy['height'] / 16 + 'em'
            })
        }

        cachedWindowWidth = window.innerWidth;

    };

    initializeVideoResize = function ($wrapper) {
        $video = $wrapper.find('video');
        $container = $wrapper;

        if ($video.length === 0) {
            return;
        }

        $video[0].addEventListener("canplay", function () {

            aspectRatio = $video.width() / $video.height();

            handleVideoResize();

            window.windowResize.add(handleVideoResize.bind(this));

        }, false);

    };

    return {
        init: initializeVideoResize
    }

})();

module.exports = heroVideoResize;
},{"./selectors":13,"enquire.js":5}],12:[function(require,module,exports){
'use strict';

var triggers = require('./triggers');
var $module;

$module = $('.homepage-panels');

if ($module.length){
	triggers.bindTriggers();
}

},{"./triggers":15}],13:[function(require,module,exports){
'use strict';

var $selectors = {};

$selectors.$module = $('.homepage-panels');
$selectors.$panelRightBtn = $selectors.$module.find('.btn-panel-right');
$selectors.$defaultPanelBtn = $selectors.$module.find('.btn-default-home-scroll');
$selectors.$panelLeftBtn = $selectors.$module.find('.btn-panel-left');
$selectors.$body = $('body');
$selectors.$topMenu = $('.global-menu-bar .menu-triggers');

exports.$selectors = $selectors;

},{}],14:[function(require,module,exports){
'use strict';

var updateView = require('./updateView').updateView;

var state,
	api,
	updateView;

state = {
	isDesktopView: false	// initialize isDesktopView to false
};

api = {
	changeView: function(newState){
		if (typeof newState === 'boolean'){
			state.isDesktopView = newState;
		}
		updateView({type: 'fullpage', isDesktopView: this.isDesktopView()});
	},

	isDesktopView: function(){
		return state.isDesktopView;
	}
};

module.exports = api;

},{"./updateView":16}],15:[function(require,module,exports){
'use strict';

var state = require('./state'),
	$selectors = require('./selectors').$selectors,
	mediaQuery = require('enquire.js'),
    heroVideoResize = require('./heroVideoResize');

var bindMediaQueryTrigger,
	bindSlideControls,
	bindBackgroundVideoTriggers,
	bindTriggers;

bindMediaQueryTrigger = function(){
	mediaQuery.register('(min-width: 767px)',{
		match: function(){
			state.changeView(true);
		},

		unmatch: function(){
			state.changeView(false);
		},

		setup: function(){
			state.changeView();
		}
	});
};

bindSlideControls = function(){

	$selectors.$panelRightBtn.click(function(){

		if (state.isDesktopView()){
			$.fn.fullpage.moveSlideRight();
		} else {
			$.fn.fullpage.moveSectionDown();
		}

	});

	$selectors.$defaultPanelBtn.click(function () {

	    if (state.isDesktopView()) {
	        $.fn.fullpage.moveSlideRight();
	    } else {
	        $.fn.fullpage.moveSectionDown();
	    }

	});

	$selectors.$panelLeftBtn.click(function(){
		if (state.isDesktopView()){
			$.fn.fullpage.moveSlideLeft();
		} else {
			$.fn.fullpage.moveSectionUp();
		}
	});
};

bindBackgroundVideoTriggers = function(){
	$selectors.$module.find('.background-video').on('playing', function(){
		$(this).addClass('video-is-playing');
	});
};

bindTriggers = function () {
	bindMediaQueryTrigger();
	bindBackgroundVideoTriggers();
	bindSlideControls();
	heroVideoResize.init($selectors.$module);
};

exports.bindTriggers = bindTriggers;

},{"./heroVideoResize":11,"./selectors":13,"./state":14,"enquire.js":5}],16:[function(require,module,exports){
'use strict';

var $selectors = require('./selectors').$selectors,
	globalMenuBar = require('./../global-menu-bar'),
	homepageVideoCarousel = require('./../homepage-video-carousel');

var canPanelsScroll,
	updateView,
	updateFullPageOrientation;

canPanelsScroll = function(){
	return globalMenuBar.isFlyoutClosed() &&
		homepageVideoCarousel.isVideoOverlayClosed();
};

updateFullPageOrientation = function(config){
	var arrangeHorizontal,
		arrangeVertical,
		handler;

	// Undo the previous fullpage setup
	if ($.fn.fullpage.destroy){
		$.fn.fullpage.destroy('all');
	}

	arrangeHorizontal = function(){
		var $sections,
			$sectionContainer;

		$sections = $selectors.$module.find('.section');

		if ($sections.length === 1){
			return;
		}

		$sectionContainer = $('<div class="section"></div>');
		$sectionContainer.appendTo($selectors.$module);

		$sections.removeClass('section').addClass('slide');
		$sections.appendTo($sectionContainer);
	};

	arrangeVertical = function(){
		var $slides,
			$section;

		$slides = $selectors.$module.find('.slide');

		if ($slides.length === 0){
			return;
		}

		$section = $slides.parent();
		$slides.removeClass('slide').addClass('section').appendTo($selectors.$module);
		$section.remove();
	};

	if (config.isDesktopView){
		arrangeHorizontal();
	} else {
		arrangeVertical();
	}

	handler = function(){
		var $this = $(this);

		if ($this.hasClass('homepage-panel-content-container')){
			setTimeout(function(){
				$this.addClass('show-intro-animation');
			}, 100);
		}
	};

	$selectors.$module.fullpage({
		controlArrows: false,
		loopHorizontal: false,
		afterLoad: handler,
		afterSlideLoad: handler,
		normalScrollElements: '.typeahead-results, .global-menu-flyout .scrollable-container',
		onScroll: function(direction){
			if (canPanelsScroll()){
				if (direction === 'down'){
					$.fn.fullpage.moveSlideRight();
				} else {
					$.fn.fullpage.moveSlideLeft();
				}
			}
		},
		afterRender: function(){

			// In iOS - when you switch orientations, the top: 0 position is at the top of
			// the screen behind to url bar. When you reload the page (with no orientations witch),
			// the top 0 position is at the bottom of the Url bar. Forcing a scroll top the top
			// makes the position consistent so that top 0 is always at the bottom of the Url bar
			// More details can be found here: https://github.com/alvarotrigo/fullPage.js/issues/2414
			setInterval(function(){
				$(window).scrollTop(0);
				$(window).trigger('resize');
			}, 600);

			setTimeout(function(){
				$(window).trigger('resize');
				$('.homepage-loading-overlay').fadeOut();
			}, 600);
		},
		afterResize: function(){
		}
	});
};

updateView = function(config){
	if (config.type === 'fullpage'){
		updateFullPageOrientation(config);
	}
};

exports.updateView = updateView;
},{"./../global-menu-bar":8,"./../homepage-video-carousel":17,"./selectors":13}],17:[function(require,module,exports){
'use strict';

var $module,
	$backgroundContainer,
	$carousel,
	$videoContainer,
	$videoPlay,
	$videoOverlay,
	$videoBackgroundCover,
	$body,
	state,
	setupVideosAndImages,
	controlVideo,
	initializeSlick,
	initialize,
	updateBackground,
	updateNavigationOffset,
	mediaQuery;


mediaQuery = require('enquire.js');

$body = $('body');
$module = $('.js-homepage-video-carousel');

$carousel = $module.find('.js-carousel-videos');
$backgroundContainer = $module.find('.background-skew');
$videoContainer = $('.homepage-videos');
$videoPlay = $module.find('.btn-play-video');
$videoOverlay = $('.homepage-video-overlay');
$videoBackgroundCover = $('<div class="video-background-cover"></div>');


state = {
	isVideoOverlayClosed: true,
	currentSlide: 1,
	isMobile: false,
	updateCurrentSlide: function(nextSlide){
		this.currentSlide = nextSlide;
		updateBackground();
		updateNavigationOffset();
	}
};


//setup media qquery
mediaQuery.register('(min-width: 767px)',{
    match: function(){
        state.isMobile = false;
    },

    unmatch: function(){
        state.isMobile = true;
    }
});


controlVideo = function(){
    var $currentVideo;

    if ($videoContainer.find('.homepage-video iframe')[0] === undefined)
        return;

	$currentVideo = $videoContainer.find('.homepage-video iframe')[0].contentWindow;
	if (state.isVideoOverlayClosed){
	    $currentVideo.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
	    $($videoContainer.find('.homepage-video iframe')[0]).empty();
	    $($videoContainer.find('.current-video-item')[0]).empty();
	} else {
		$currentVideo.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
	}
};

updateBackground = function(){
	var prefix = 'show-background-';

	$body.removeClassPrefix(prefix);
	$body.addClass(prefix + state.currentSlide);
};

updateNavigationOffset = function (){
	var $currentSlideEl

	if (state.isMobile) {
        $carousel.css('margin-top', '');
    } else {
		
		var $currentSlideEl = $carousel.find('[data-slick-index=' + state.currentSlide + ']');

		$carousel.css('margin-top', $currentSlideEl.height() + 'px');
    }
};

initializeSlick = function(){

	var settings;

	settings = {
		desktopInitialSlide: 1,
		mobileInitialSlide: 0,
		responsiveBreakpoint: 768
	};

	$carousel.on('init', function(){
		if (window.innerWidth < settings.responsiveBreakpoint){
			state.updateCurrentSlide(settings.mobileInitialSlide);

		} else {
			state.updateCurrentSlide(settings.desktopInitialSlide);
		}
	});

	$carousel.slick({
		slidesToShow: 3,
		initialSlide: settings.desktopInitialSlide,
		asNavFor: '.homepage-videos',
		centerMode: true,
		focusOnSelect: true,
		arrows: false,
		infinite: true,
		responsive: [
			{
				breakpoint: settings.responsiveBreakpoint,
				settings: {
					slidesToShow: 1,
					initialSlide: settings.mobileInitialSlide,
					infinite: true,
					arrows: true,
					prevArrow: '<button type="button" class="slick-prev"></button>',
					nextArrow: '<button type="button" class="slick-next"></button>'
				}
			}
		]
	});

	$carousel.on('beforeChange', function(event, slick, currentSlide, nextSlide){
		state.updateCurrentSlide(nextSlide);
	});

	$videoContainer.slick({
		initialSlide: settings.desktopInitialSlide,
		arrows: false,
		speed: 0,
		asNavFor: '.js-carousel-videos',
		responsive: [
			{
				breakpoint: settings.responsiveBreakpoint,
				settings: {
					initialSlide: settings.mobileInitialSlide
				}
			}
		]
	});
};

setupVideosAndImages = function(){

	var $videos;

	$backgroundContainer.empty();
	$videos = $carousel.find('.js-carousel-video');

	if ($videos.length > 0) {
		$body.append($videoBackgroundCover);
	}

	$videos.each(function(index, video){
	    var $video,
			backgroundUrl,
			videoUrl,
            videoAnchor,
            videoAnchorTitle;

		$video = $(video);

		backgroundUrl = $video.data('background-image-url');
		videoUrl = $video.data('video-url');
		videoAnchor = $video.data('video-anchor');
		videoAnchorTitle = $video.data('anchor-title');

		if (backgroundUrl){
			$backgroundContainer.append('<div class="background-skew-reverse" style="background-image: url(\''+ backgroundUrl +'\')"> </div>');
		}
	});
};

function toggleVideoBackgroundCover() {
	$videoBackgroundCover.toggleClass('is-visible', !state.isVideoOverlayClosed);
}

initialize = function(){

	var closeVideo,
		openVideo;

	var carouselItems = $('.js-carousel-videos').children().clone();

	if (carouselItems.length <= 3) {
	    for (var x = 0; x < 5 - carouselItems.length; x++) {
	        $('.js-carousel-videos').append(carouselItems);
	    }
	}

	setupVideosAndImages();
	initializeSlick();

	closeVideo = function(){
		state.isVideoOverlayClosed = true;
		$body.removeClass('show-video-overlay');
		toggleVideoBackgroundCover();
		controlVideo();
	};

	openVideo = function(){
		state.isVideoOverlayClosed = false;
		$body.addClass('show-video-overlay');
		toggleVideoBackgroundCover();
		controlVideo();
	};

	$videoPlay.click(function () {
		var handleBodyClick,
			bodyClickArmedFlag,
            backgroundUrl,
            videoUrl,
            videoAnchor,
            videoAnchorTitle,
		    $currentVideo = $('.js-carousel-video.slick-active.slick-center');

		if ($currentVideo !== undefined && $currentVideo !== '') {
		    backgroundUrl = $currentVideo.data('background-image-url');
		    videoUrl = $currentVideo.data('video-url');
		    videoAnchor = $currentVideo.data('video-anchor');
		    videoAnchorTitle = $currentVideo.data('anchor-title');

		    $videoContainer.html([
				'<li class="homepage-video current-video-item">',
					'<div class="width-wrapper">',
						'<div class="video-container">',
							'<iframe width="925" height="620" src="' + videoUrl + '?enablejsapi=1&version=3" frameborder="0" allowfullscreen="true" allowscriptaccess="always"></iframe>',
						'</div>',
						'<a href="' + videoAnchor + '" class="btn-call-to-action">',
                        (videoAnchorTitle !== undefined ? videoAnchorTitle : ""),
                        '</a>',
						'<button class="btn-close-video"></button>',
					'</div>',
				'</li>'].join(''));
	
		    openVideo();

		    bodyClickArmedFlag = false;

		    handleBodyClick = function(event){
			    if (!bodyClickArmedFlag){
				    bodyClickArmedFlag = true;
				    return $body.one('click', handleBodyClick);
			    } else {
				    if ($(event.target).closest('.video-container').length === 0){
					    closeVideo();
				    }
			    }
		    };

		    $body.one('click', handleBodyClick);
		}
	});

	$videoOverlay.on('click', '.btn-close-video', closeVideo);
};

initialize();

exports.isVideoOverlayClosed = function(){
	return state.isVideoOverlayClosed;
};
},{"enquire.js":5}],18:[function(require,module,exports){
var $module,
    $carousel,
    $carouselNav,
    sideCount,
    updateNavOffset,
    mediaQuery,
    moduleState,
    customTertiaryFormat;

$module = $('.image-video-module');

if (!$module.length ){
    return;
}


moduleState = {
  isMobile: true,
  slideCount: $module.find('.media-item').length
};

mediaQuery = require('enquire.js');

//assign jquery selectors
$carousel = $module.find('.media-carousel');
$carouselNav = $module.find('.media-details');

//helper functions
updateNavOffset = function (currentSlideIndex) {
    var $currentSlideEl;
    
    if (moduleState.isMobile) {
        $carouselNav.css('margin-top', '');

    } else {
        if (moduleState.slideCount === 1) return;

        $currentSlideEl = $carouselNav.find('[data-slick-index=' + currentSlideIndex + ']');

        $carouselNav.css('margin-top', $currentSlideEl.height() + 'px');
    }
};

customTertiaryFormat = function (currentSlideIndex) {
    $carouselNav.attr('data-format-current-slide', currentSlideIndex);
};

//event needs to be before initialization
$carouselNav.on('init', function(event, slick) {

    if (moduleState.slideCount < 4 && moduleState.slideCount > 1  ) {
        customTertiaryFormat(0);
    }
    setTimeout(function() {
        updateNavOffset(0);    
    }, 800);
});

//Initialize carousel functionality with slick
$carousel.slick({
    asNavFor: '.media-details',
    prevArrow: '<button class="btn-slick-prev"></button>',
    nextArrow: '<button class="btn-slick-next"></button>',
    infinite: true,
    adaptiveHeight: true
});

$carouselNav.slick({
    asNavFor: '.media-carousel',
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    focusOnSelect: true,
    arrows: false,
    infinite: true,
    responsive: [
       {
           breakpoint: 767,
           settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               centerMode: false
           }
       }
    ]
});

// Slick Helper Methods
$carousel.on('beforeChange', function(event, slick, currentSlide, nextSlide){
    updateNavOffset(nextSlide);

    if (moduleState.slideCount < 4 && moduleState.slideCount > 1  ) {
        customTertiaryFormat(nextSlide);
    }

});

//setup media qquery
mediaQuery.register('(min-width: 768px)',{
    match: function(){
        moduleState.isMobile = false;
    },

    unmatch: function(){
        moduleState.isMobile = true;
    }
});
},{"enquire.js":5}],19:[function(require,module,exports){
'use strict';

$('.js-jump-link').on('click', function () {
    var text = $('.jump-link-text').data('text');
	if (text)
        return confirm(text);

    return true;
});
},{}],20:[function(require,module,exports){
'use strict';

$(document).ready(function(){
	if($('body').hasClass('pdf')) return;
	var $shareTools,
		$articleContent,
		controller,
		containerScene;

	var pinnedClass = '.js-pin-to-scroll';
	var triggerClass = '.js-pin-to-scroll-wrapper';
	var contentClass = '.js-pin-to-scroll-reference';
	var offset = -100;

	$shareTools = $(pinnedClass+'.desktop');

	if (!$shareTools.length) return;

	controller = new ScrollMagic.Controller();
	$articleContent = $(triggerClass+' '+ contentClass);
	if(!$(triggerClass).length) return;
	containerScene = new ScrollMagic.Scene({
		triggerElement: triggerClass,
		triggerHook: 0,
		duration: function(){
			var calc = ($articleContent.height() - $shareTools.height());
			return (isNaN(calc)) || calc < 0 ? 0 : calc;
		},
		offset: offset
	})

	.setPin(pinnedClass+'.desktop')
	.setClassToggle(pinnedClass+'.desktop', 'fixed-scroll')
	.addTo(controller);

});

},{}],21:[function(require,module,exports){
'use strict';

var	$header = $('.page-header-with-input');
var $currentHeight;

function init() {
	$currentHeight = $header.find('.header-filters-list').css('height');
	$header.find('.js-filters').css('height', $currentHeight);
	$(window).resize(handleHeight);
}

init();

function handleHeight() {
	var $elTarget = $header.find('.js-filters'); 
	var $elRef = $header.find('.header-filters-list');
	var $height = $elRef.css('height');

	if ($currentHeight !== $height && $elTarget.hasClass('active')) {
		$currentHeight = $height;
		$elTarget.css('height', $height);
	} else {
		return;
	}
}

$header.on('click', '.js-filters-toggle', function(e){
	e.preventDefault();
	var $this = $(this);
	$this.toggleClass('active');
	var $height = $header.find('.header-filters-list').css('height');
	if ($header.find('.js-filters').hasClass('active')) {
		// immediately remove overflow class to show dropdowns
		$header.find('.js-filters').removeClass('overflow');
		$header.find('.js-filters').css('height', "0px");

		$(window).off("resize", handleHeight);
	} else {
		$header.find('.js-filters').css('height', $height);
		// add overflow class to show dropdowns, with 1s delay
		window.setTimeout(function() {
			$header.find('.js-filters').addClass('overflow');
		}, 1000);

		$(window).resize(handleHeight);
	}
	$header.find('.js-filters').toggleClass('active');
});

// if(window.location.search.indexOf('advancedSearchOpen') > -1){
// 	$header.find('.js-filters-toggle').trigger('click');
// }

},{}],22:[function(require,module,exports){
var $body = $('body');

$body.on('click', '.btn-readmore-expand', function () {
    var $this = $(this);
    var readMorePanelId = $this.attr('data-read-more');
    $('#' + readMorePanelId).toggle();
    $this.toggle();

    $($this.parent()).css("margin-top", "2em");

    var firstItemInContent = $($this).next().children().first();
    if (typeof firstItemInContent != "undefined") {
        var elemName = $(firstItemInContent)[0].tagName;
        if (elemName == "UL") {
            $($this.parent()).css("margin-top", "0px");
        }
    } 
    $($this.parent().parent().children().first()).css("margin-bottom", "0px");
});

$body.on('click', '.tabbed-content .btn-expand', function(){
    var $this = $(this);
    if ($this.hasClass("btn-ignore-default"))
        return;

    $($this.parent()).addClass("tabbed-content-readmore");
	$this.closest('.tabbed-content').find('.read-more-content').slideToggle();
	$this.toggleClass('active');
});

$('.show-all-authors').click(function () {
    var $this = $(this);
    $this.toggleClass('active');
    $('.author-card-extra').toggle();
});
},{}],23:[function(require,module,exports){
var $expandDiv = $('.no-child-services');
if($expandDiv === undefined)
    return;

$expandDiv.unbind();

$($expandDiv).click(function (e) {
	e.preventDefault();
	var itemUrl = $(this).data("serviceurl");
	window.location = itemUrl;
});


},{}],24:[function(require,module,exports){
var $slideOpenBox =  $('.js-slide-open');
if(!$slideOpenBox.length) return;
$(".span-expand").click(function (e) {
	e.preventDefault();
	$(this).toggleClass("active");
	$(this).parent().toggleClass("active");
	$(this).children(".btn-expand").toggleClass("active")
	var serviceList = $(this).data("servicename");
	var obj = $slideOpenBox.find("[data-servicelist='" + serviceList + "']")[0];
	$(obj).slideToggle("fast");
});
},{}],25:[function(require,module,exports){
var $body = $('body');

if ($body.hasClass('pdf')) {
  return;
}

$body.find('.btn-tab-control').on('click', function () {
	var $this = $(this);
	var reference = $this.data('tabsReference');
	var tabId = $this.data('tabid');
	var tabindex = $(this).parent().children().index(this);
	if ($this.attr('href') && $this.attr('href').length > 0) {} else {
		$this.siblings().removeClass('current-tab');
		$this.addClass('current-tab');
	}

	var $referenceEl = $('#' + reference);
	if ($referenceEl.length) {
		var tabSelector = (tabId !== undefined) ? '.tabs-item.' + tabId : '.tabs-item:nth(' + tabindex + ')';
		var $selectedItem = $referenceEl.find(tabSelector);
		if ($selectedItem.length > 0) {
			$('.tabs-item').hide();
			$selectedItem.show();
		} else {
			// checking if handling general tabs
			var generalTab = $('#' + tabId);
			if (generalTab.length > 0) {
				var sectionTabs = generalTab.find('.section-tab');
				var sectionNavButtons = generalTab.find('.btn-section-tab-control');

				if (sectionTabs.length > 0 && sectionNavButtons.length > 0) {
					sectionTabs.hide();
					sectionNavButtons.removeClass('current-section');
					sectionTabs.first().show();
					sectionNavButtons.first().addClass('current-section');
				}
			}
		}
	} else if ($this.hasClass('bio-tab-button')) {
		var $content = $('.tabbed-content');
		tabSelector = '#tab-' + ((tabId !== undefined) ? '' + tabId : '' + tabindex);
		$content.html($(tabSelector).html());		
	}
});

$('.btn-section-tab-control').on('click', function () {
	var $this = $(this);
	var reference = $this.attr('data-section-tabs-reference');
	var tabId = $this.attr('data-tabid');
	var $tabsElement = $('#' + reference);

	$this.siblings().removeClass('current-section');
	$this.addClass('current-section');

	if ($tabsElement.length) {
		var selectedTab = $tabsElement.find('#' + tabId);
		$('.section-tab').hide();
		selectedTab.show();
	}
});

var bindTabLinks = function () {
	$('.btn-next-tab').click(function () {
		var nextButton = $(this).attr('data-next-button');
		$('#' + nextButton).click();

		$('html, body').animate({
			scrollTop: $("#tab-nav").offset().top - $('.homepage-navigation').height()
		}, 250);
	});
};

const setUrl = function ($tab) {
  const tabId = $tab.data('tabid');
  if (tabId && 'URLSearchParams' in window) {
    const url = new URL(window.location)
    url.searchParams.set("tab", tabId.replace('button-', ''))
    history.replaceState(null, '', url);
  }
}

var showTabForControl = function ($control) {
	if (!$control || !$control.length) {
		return;
	}
	var index = $control.index();
	$control.siblings('.current-tab').removeClass('current-tab');
	$control.addClass('current-tab');
	var $tabbedContent = $control.closest('.tabbed-content-module, .resource-content-module');
	$tabbedContent.find('.tabs-item').hide();
	$tabbedContent.find('#tab-' + index).show();
};

var getUrlParameter = function getUrlParameter(sParam) {
	var sPageURL = window.location.search.substring(1),
		sURLVariables = sPageURL.split('&'),
		sParameterName,
		i;

	for (i = 0; i < sURLVariables.length; i++) {
		sParameterName = sURLVariables[i].split('=');

		if (sParameterName[0] === sParam) {
			return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
		}
	}
};

var bindHandlers = function () {
	setTimeout(function () {
		if (typeof window.$ === 'function') {
			$(document).ready(function () {

				$('.tabbed-content-module, .resource-content-module').each(function () {
					var $this = $(this);
					var showTab = function (index) {
						$this.find('.tabs-item').hide();
						$this.find('#tab-' + index).show();
					};
					$this.find('.btn-tab-control').click(function () {
            var $this = $(this);
            setUrl($this);
						var index = $this.parent().children().index(this);
						showTab(index);						
					});

					$('.profile-intro-readbio').on('click', function () {
						$(".tabs-control").find('.current-tab').removeClass('current-tab');
						$(".tabs-control").find('#button-bio').addClass('current-tab');

						var index = $(".tabs-control .slick-track").length ? $(".tabs-control .slick-track").children().index($(".tabs-control").find('#button-bio')) : $(".tabs-control").children().index($(".tabs-control").find('#button-bio'));

						console.log(index);

						$('html, body').animate({
							scrollTop: $(".profile-intro-readbio").offset().top
						}, 350);
						showTab(index);
					});

					var firstActive = $this.find('.btn-tab-control.current-tab').get(0);

					if (firstActive) {
						var firstactiveindex = $(firstActive).parent().children().index(firstActive);
						showTab(firstactiveindex);
					}

					// Some tabs have a carousel. Since tabs are display none by default
					// the carousel is unable to calculate the correct width for the carousel track,
					// Therefore we should trigger a 'resize' event to allow the carousel to
					// calculate the correct width after it's no longer display none
					$(window).trigger('resize');
				});
			});
		} else {
			bindHandlers();
		}
	}, 50);
};

const tabParam = getUrlParameter('tab')
console.log(tabParam)
if (tabParam) {
  let currentTab = $(`.btn-tab-control[data-tabid='button-${tabParam}']`)
  if (currentTab && currentTab.length > 0) {
    showTabForControl($(currentTab[0]))
  }
}

var $slickNav = $('.slick-tabs-control');

function initSlickNav() {
	// hide prev button when on first item
	var $initialIndex; 

	function init() {
		// after slide change

		function checkNext() {
			var $nextBtn = $('.slick-tabs-control--next');
			var trackPos = $(window).width() - ($slickNav.offset().left + $slickNav.outerWidth()); //distance from right edge of track to right edge of browser
			var lastItem = $slickNav.find('.slick-track .btn-tab-control:last-child').length ? $(window).width() - ($slickNav.find('.slick-track .btn-tab-control:last-child').offset().left + $slickNav.find('.slick-track .btn-tab-control:last-child').outerWidth()) : 0;
			if (lastItem > trackPos) {
				$nextBtn.addClass('slick-disabled');
				$nextBtn.attr('aria-disabled', true);
			}  else {
				$nextBtn.removeClass('slick-disabled');
				$nextBtn.attr('aria-disabled', true);
			}
		}

		function slideTo(i) {
			$slickNav.slick('slickGoTo', i);
		}

		$slickNav.on('afterChange', function(event, slick, currentSlide, nextSlide){
			checkNext();
		});

		$slickNav.slick({
			dots: false,
			infinite: false,
			arrows: true,
			speed: 300,
			slidesToShow: 1,
			variableWidth: true,
			prevArrow: '<button type="button" class="slick-tabs-control--prev"></button>',
			nextArrow: '<button type="button" class="slick-tabs-control--next"></button>',			
			initialSlide: 0
		});

		checkNext();

		// set initial nav item
		if ($initialIndex > 0 ) {
			slideTo($initialIndex);
		}
	}

	setTimeout(function() {
		var $initialItem = $slickNav.find('.current-tab');
		$initialIndex = $initialItem.index() > -1 ? $initialItem.index() : 0; //get index of current page item		
		init(); //init carousel
	}, 50);
}

function checkSlickNav() {	
	// init/destroy slick carousel nav as needed
	var trackLength;
	var slickInit = false; //is slick initialized? init to false
	
	function resizeHandler() {
		var itemLength = 0;
		
		trackLength = $slickNav.outerWidth();

		$('.slick-tabs-control .btn-tab-control').each(function() {
			itemLength = itemLength + $(this).outerWidth();
		})

		if (itemLength > trackLength) {
			if (!$slickNav.hasClass('slick-initialized') && !slickInit) {			
				initSlickNav();
				slickInit = true;
			}
		} else {
			if ($slickNav.hasClass('slick-initialized')) {
				$slickNav.slick('unslick'); //destroy slick
				slickInit = false;
			}
		}
	}

	resizeHandler(); //init carousel nav
	window.addEventListener('resize', resizeHandler);
}

bindHandlers();

bindTabLinks();

if ($slickNav.length) {
	checkSlickNav();	
}
},{}],26:[function(require,module,exports){
'use strict';

var $component,
	$input;

$component = $('.js-typeahead-search, .js-typeahead-search-container');

if (!$component.length){
	return;
}

$input = $component.find('.text-input');

$input.keyup(function(){
	var $this,
		$typeaheadResults,
		$parent,
		$thisComponent,
		value,
		updateTypeaheadResults,
		showTypeaheadClass;

	$this = $(this);
	$thisComponent = $this.closest('.js-typeahead-search, .js-typeahead-search-container');
	value = $this.val();
	showTypeaheadClass = 'show-typeahead';

	if ($thisComponent.hasClass('js-typeahead-search')){
		$parent = $this.parent();
	} else if ($thisComponent.hasClass('js-typeahead-search-container')){
		$parent = $this.closest('.search-input-typeahead-container');
	}

	if (value.length < 3){
		$parent.removeClass(showTypeaheadClass);
		return;
	}

	$typeaheadResults = $parent.find('.typeahead-results');

	updateTypeaheadResults = function(results){
		$typeaheadResults.empty();

		if (results && results.length){
			results.forEach(function(item){
				var element,
					$item;

				element = ['<li class="typeahead-result">',
					'<a href="', item.Url, '">', item.Label, '</a>',
					'</li>'].join('');

				$item = $(element);
				$item.appendTo($typeaheadResults);
			});
		} else {
			$typeaheadResults.append($('<li class="typeahead-result no-results">No Results Found</li>'));
		}

		$parent.addClass(showTypeaheadClass);
	};

	//TODO - replace the mockdata below and replace with AJAX request
	var mockData =[];
	setTimeout(updateTypeaheadResults.bind(null, mockData), 100);
});

},{}],27:[function(require,module,exports){
'use strict';

// http://paulirish.com/2011/requestanimationframe-for-smart-animating/
// http://my.opera.com/emoller/blog/2011/12/20/requestanimationframe-for-smart-er-animating

// requestAnimationFrame polyfill by Erik Möller
// fixes from Paul Irish and Tino Zijdel

(function () {
    var lastTime = 0,
        globalVars;

    var vendors = ['ms', 'moz', 'webkit', 'o'];
    for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
        window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame'];
        window.cancelAnimationFrame = window[vendors[x] + 'CancelAnimationFrame'] || window[vendors[x] + 'CancelRequestAnimationFrame'];
    }

    if (!window.requestAnimationFrame)
        window.requestAnimationFrame = function (callback, element) {
            var currTime = new Date().getTime();
            var timeToCall = Math.max(0, 16 - (currTime - lastTime));
            var id = window.setTimeout(function () { callback(currTime + timeToCall); },
	          timeToCall);
            lastTime = currTime + timeToCall;
            return id;
        };

    if (!window.cancelAnimationFrame)
        window.cancelAnimationFrame = function (id) {
            clearTimeout(id);
        };
    //helper functions
    if (!window.helperUtils)
        window.helperUtils = {
            getDocDimen: function () {
                var winDimen = {
                    height: window.innerHeight,
                    width: window.innerWidth
                };

                return winDimen;
            },
            mobileCheck: function () {
                return (window.innerWidth < 768) ? true : false;
            },
            isMobile: false
        };

    if (!window.parseQueryString)
        window.parseQueryString = function (str) {
            if (typeof str !== 'string') {
                return {};
            }
            if (!str) {
                return {};
            }
            return str.substring(str.indexOf('?')+1).split('&').reduce(function (ret, param) {
                var parts = param.replace(/\+/g, ' ').split('=');
                ret[parts[0]] = parts[1] === undefined ? null : decodeURIComponent(parts[1]);
                return ret;
            }, {});
        };

    if (!window.getDocHeight)
        window.getDocHeight = function () {
            var D = document;
            return Math.max(
                D.body.scrollHeight, D.documentElement.scrollHeight,
                D.body.offsetHeight, D.documentElement.offsetHeight,
                D.body.clientHeight, D.documentElement.clientHeight
            );
        };

    if (!window.doubleTabZoomPrevent) //Adding this as per client request to disable double tap zoom.
        window.doubleTabZoomPrevent = (function () {
            var lastTouchEnd = 0;
            document.documentElement.addEventListener('touchend', function (event) {
                var now = (new Date()).getTime();
                if (now - lastTouchEnd <= 300) {
                    event.preventDefault();
                }
                lastTouchEnd = now;
            }, false);
        })();

    if (!window.windowResize)
    window.windowResize = (function () {
        var callbacks = [],
            running = false;

        // fired on resize event
        function resize() {

            if (!running) {
                running = true;

                if (window.requestAnimationFrame) {
                    window.requestAnimationFrame(runCallbacks);
                } else {
                    setTimeout(runCallbacks, 66);
                }
            }

        }

        // run the actual callbacks
        function runCallbacks() {

            callbacks.forEach(function (callback) {
                callback();
            });

            running = false;
        }

        // adds callback to loop
        function addCallback(callback) {

            if (callback) {
                callbacks.push(callback);
            }

        }

        return {
            // public method to add additional callback
            add: function (callback) {
                if (!callbacks.length) {
                    window.addEventListener('resize', resize);
                }
                addCallback(callback);
            }
        };
    })();
}());

},{}],28:[function(require,module,exports){
'use strict';
require('./features/utils');
$(document).ready(function () {
	require('./features/global-menu-bar');
	require('./features/homepage-panels');
	require('./features/text-input-component');
	require('./features/homepage-content');
	// require('./features/homepage-panel-content');
	require('./features/homepage-video-carousel');
	require('./features/drop-down');
	require('./features/tab-controls');
	require('./features/image-video-module');
	require('./features/professionals-advanced-search.js');
	require('./features/pinToScroll.js');
	require('./features/slideopen-box.js');
	require('./features/readMore.js');
	require('./features/highlight-helper.js');
	require('./features/disclaimer.js');
    require('./features/slideopen-box-override');
    require('./features/jump-link.js');
});

},{"./features/disclaimer.js":6,"./features/drop-down":7,"./features/global-menu-bar":8,"./features/highlight-helper.js":9,"./features/homepage-content":10,"./features/homepage-panels":12,"./features/homepage-video-carousel":17,"./features/image-video-module":18,"./features/jump-link.js":19,"./features/pinToScroll.js":20,"./features/professionals-advanced-search.js":21,"./features/readMore.js":22,"./features/slideopen-box-override":23,"./features/slideopen-box.js":24,"./features/tab-controls":25,"./features/text-input-component":26,"./features/utils":27}]},{},[28]);
